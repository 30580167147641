import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  seminarDetailForAdmin,
  seminarAssistanceIndex,
  seminarCancelPatchUpdateByAdmin,
  myPage,
  membersWithStripeCustomers,
  paramsIndex,
  cancelServicePlan,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import StandardDialog from '../../../Dialog/standardDialog';
import Footer from '../../../Footer/footer';
import { formatDate } from '../../../General/clock';
import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '0.9rem',
      fontFamily: theme.typography.fontFamily,
    },
  },
  seminarSpec: {
    display: 'flex',
    justifyContent: 'start',
    margin: '0px 0px 16px 0px',
    padding: '0px 16px',
    width: '100%',
    fontSize: '0.9rem',
    '& > #spec1, & > #spec2': {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      textAlign: 'start',
      '& > div': {
        display: 'flex',
        justifyContent: 'start',
        width: '100%',
        '& > div': {
          width: '30%',
          textAlignLast: 'start',
          fontWeight: 'bold',
        },
        '@media screen and (max-width:700px)': {
          flexDirection: 'column',
          '& > div': {
            width: '100%',
            fontStyle: 'italic',
          },
          '& > span': {
            paddingLeft: '16px',
          },
        },
      },
      '& > #notes': {
        '& > span': {
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
        },
      },
    },
    '@media screen and (max-width:400px)': {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
        '& > div': {
          marginBottom: '16px',
        },
      },
    },
  },
  forManagement: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 16px 80px 16px',
    padding: '4px 16px',
    height: '160px',
    fontSize: '0.9rem',
    textAlign: 'start',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    border: 'solid 0.1px grey',
    '& > span': {
      fontSize: '0.8rem',
      color: 'grey',
    },
  },
  createBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #createbtn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #createbtn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
    },
    '& #createBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #createBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  viewDataBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '0px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #btn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #btn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
      '& #btn4': {
        backgroundColor: 'rgba(124,42,46,0.5)',
        color: '#000',
      },
      '& #btn3': {
        backgroundColor: 'rgba(124,42,46,0.3)',
        color: '#000',
      },
    },
    '& #viewBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  downLoadBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      //   cursor: 'not-allowed', // downloadが機能したら削除
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer', // downloadが機能したら復活
        // pointerEvents: 'none', // downloadが機能したら削除
      },
    },
    '& #dlBtn1': {
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #dlBtn2': {
      textAlign: 'start',
    },
  },
  freeWrightBtnContainer: {
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      //   cursor: 'not-allowed', // downloadが機能したら削除
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '0px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
    },
    '& #freeBtn1': {
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #freeBtn2': {
      textAlign: 'start',
    },
  },
  distributionBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'green',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  distributionBtnConfirm: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'grey',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'not-allowed',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  cancelBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: (props) => (props.disabled ? 'grey' : theme.palette.primary.main),
      cursor: (props) => (props.disabled ? 'not-allowed' : 'pointer'),
    },
  },
}));

const SeminarApplySituation = () => {
  const { isAuth } = useContext(AuthContext);
  const [seminar, setSeminar] = useState();
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [date, setDate] = useState();
  const [part1Num, setPart1Num] = useState();
  const [part2Num, setPart2Num] = useState();
  const [part3Num, setPart3Num] = useState();
  const [komaNum, setKomaNum] = useState();
  const [eventDate, setEventDate] = useState();
  const [dialog, setDialog] = useState(false);
  const [cancelId, setCancelId] = useState();
  const [canceler, setCanceler] = useState();
  const [recorder, setRecorder] = useState();
  const [loading, setLoading] = useState(true);
  const [stripeCustomers, setStripeCustomers] = useState();
  const [isCancel, setIsCancel] = useState(false);
  const [refund, setRefund] = useState();
  const [update, setUpdate] = useState();
  const [memberUuid, setMemberUuid] = useState();
  const [seminarPaymentMethod, setSeminarPaymentMethod] = useState('is_mixed');

  const [params, setParams] = useState();
  const [DISABLED, setDISABLED] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
    disabled: permissions.includes('admin') ? false : DISABLED,
  });

  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'セミナー一覧', path: '/seminar_index' },
    { name: '申込状況一覧', path: '/seminar_apply_situation' },
  ];

  // if (stripeCustomers) console.log('stripeCustomers:', stripeCustomers);
  // if (viewData) console.log('viewData:', viewData);
  // if (isCancel) console.log('isCancel:', isCancel);
  // if (memberUuid) console.log('memberUuid:', memberUuid);
  // if (seminar) console.log('seminar:', seminar);

  // init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    myPage(setRecorder);
    paramsIndex(setParams);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const part1 = data.map((d) => {
        return d.part1;
      });
      const part2 = data.map((d) => {
        return d.part2;
      });
      const part3 = data.map((d) => {
        return d.part3;
      });
      setPart1Num(part1.filter(Boolean).length);
      setPart2Num(part2.filter(Boolean).length);
      setPart3Num(part3.filter(Boolean).length);
    }
  }, [data]);

  useEffect(() => {
    if (viewData) {
      setHeightSize(viewData.length * ROWHEIGHT * 1.4 + 122);
    }
  }, [viewData]);

  useEffect(() => {
    setViewData(data);
  }, [data]);

  useEffect(() => {
    if (seminar) {
      setEventDate(new Date(seminar.event_date_start_time));
      let num = 0;
      if (seminar.part_one_start_time) num += 1;
      if (seminar.part_two_start_time) num += 1;
      if (seminar.part_three_start_time) num += 1;
      // if (seminar.part_four_start_time) num += 1;
      setKomaNum(num);

      if (seminar?.payment_method) {
        const paymentMethod = seminar?.payment_method?.is_mixed
          ? 'is_mixed'
          : seminar.payment_method.is_only_cash
          ? 'is_only_cash'
          : seminar.payment_method.is_only_credit_card
          ? 'is_only_credit_card'
          : 'is_mixed';
        setSeminarPaymentMethod(paymentMethod);
      }
    }
  }, [seminar]);

  useEffect(() => {
    if (location.state) {
      seminarDetailForAdmin(setSeminar, location.state.uuid);
      seminarAssistanceIndex(setData, location.state.uuid);
      membersWithStripeCustomers(setStripeCustomers);
    }
  }, [location]);

  useEffect(() => {
    const query = searchParams.get('uuid');
    if (query) {
      seminarDetailForAdmin(setSeminar, query);
      seminarAssistanceIndex(setData, query);
      membersWithStripeCustomers(setStripeCustomers);
    }
  }, [searchParams]);

  useEffect(() => {
    if (params) {
      const is_run = params.find((param) => {
        return param.title === 'run_stripe_in_production';
      });
      const is_all_members = params.find((param) => {
        return param.title === 'run_stripe_all_members';
      });
      if (is_run?.apply_is) {
        if (is_all_members?.apply_is) {
          setDISABLED(true);
        }
      }
    }
  }, [params]);

  useEffect(() => {
    const permissions = localStorageArrayHandler(
      'parse',
      various.permissionKey
    );
    setPermissions(permissions);
  }, []);

  // handler
  // eslint-disable-next-line
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (flag, uuid, nameOfCanceler, memberUUID) => {
    if (flag === 'detail') {
      navigate(various.general.memberDetail, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'text') {
      navigate(various.general.seminarApplyFreeWright, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'cancel') {
      setCancelId(uuid);
      setDialog(true);
      setCanceler(nameOfCanceler);
      setMemberUuid(memberUUID);
    }
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleCancel = async () => {
    if (cancelId && recorder) {
      const data = {
        uuid: cancelId,
        email: recorder.email,
      };
      await seminarCancelPatchUpdateByAdmin(setIsCancel, data);
    }
  };

  useEffect(() => {
    // Seminarキャンセル後の返金処理
    async function cancel(data, setRefund) {
      await cancelServicePlan(data, setRefund);
    }
    if (isCancel && memberUuid && stripeCustomers) {
      const member = stripeCustomers.find((c) => {
        return c.uuid === memberUuid;
      });
      const seminar_assistant = viewData.find((v) => {
        return v.member.uuid === memberUuid;
      });

      // 「領収」していれば返金処理を行う
      if (member && seminar_assistant?.received_is) {
        const seminar_uuid = viewData[0].seminar.uuid;
        const data = {
          seminar_uuid: seminar_uuid,
          member_uuid: memberUuid,
        };
        cancel(data, setRefund);
        setDialog(false);
      } else {
        setDialog(false);
      }
      setUpdate(true);
      setIsCancel(false);
    } else if (isCancel) {
      setDialog(false);
    }
    // eslint-disable-next-line
  }, [isCancel]);

  useEffect(() => {
    if (refund) {
      alert('セミナーをキャンセルしました');
      setRefund(false);
    }
  }, [refund]);

  useEffect(() => {
    if (update) {
      seminarDetailForAdmin(setSeminar, location.state.uuid);
      seminarAssistanceIndex(setData, location.state.uuid);
      membersWithStripeCustomers(setStripeCustomers);
      setUpdate(false);
    }
    // eslint-disable-next-line
  }, [update]);

  const handleMember = (flag) => {
    if (data.length > 0) {
      const members = [...data];
      if (flag === 'all') {
        return setViewData(members);
      } else if (flag === 'one') {
        const result = [];
        members.forEach((sa) => {
          if ((sa.part1 && sa.part2) || (sa.part1 && !sa.part2)) {
            result.push(sa);
          }
        });
        return setViewData(result);
      } else if (flag === 'two') {
        const result = [];
        members.forEach((sa) => {
          if ((sa.part1 && sa.part2) || (!sa.part1 && sa.part2)) {
            result.push(sa);
          }
        });
        return setViewData(result);
      } else if (flag === 'three') {
        const result = [];
        members.forEach((sa) => {
          if (sa.part3) {
            result.push(sa);
          }
        });
        return setViewData(result);
      } else if (flag === 'four') {
        const result = [];
        members.forEach((sa) => {
          if (sa.part4) {
            result.push(sa);
          }
        });
        return setViewData(result);
      }
    }
  };

  const downloadCsv = () => {
    if (viewData.length === 0) {
      return alert('ダウンロードすべきデータがありません。');
    }
    const headersObject = {
      uuid: 'uuid',
      member: '参加者氏名',
      class: '会員属性',
      paymentMethod: '支払方法',
      part1: '第1部',
      part2: '第2部',
      part3: '第3部',
      part4: '懇親会',
      entrance_fee: '参加費',
      text: '自由記入欄',
    };

    const rejectList = ['uuid', 'seminar'];
    let headers = Object.keys(viewData[0]).filter((k) => {
      return !rejectList.includes(k);
    });
    headers.push('class', 'paymentMethod');
    if (seminar.is_screening) {
      headersObject.is_screening = '常連選考';
    } else {
      headers = headers.filter((h) => {
        return h !== 'is_screening';
      });
    }

    const rows = viewData.map((obj, index) => {
      // console.log('obj', obj);
      let result = [];

      headers.forEach((h) => {
        let value;
        if (h === 'member') {
          value = obj[h].last_name + obj[h].first_name;
        } else if (
          h === 'part1' ||
          h === 'part2' ||
          h === 'part3' ||
          h === 'part4'
        ) {
          if (obj[h]) {
            value = '参加';
          } else {
            value = '-';
          }
        } else if (h === 'fee') {
          if (seminar.is_screening && obj.is_screening) {
            // このIF文は検証の上削除したい。
            if (obj.member.is_member || obj.member.is_training_group_member) {
              let fee = seminar.membership_fee;
              // 常連組のスクリーニングを申し込みフォームで行っている。1/3というのは、2023年５月開催の「凝縮稽古」のみなのかもしれない。場当たり的な対応。
              fee = fee / 3;
              const unitPrice = fee / komaNum;
              value = String(
                [obj.part1, obj.part2, obj.part3].filter(Boolean).length *
                  unitPrice
              );
            }
          } else {
            // こちらのみで機能する模様
            value = String(obj.entrance_fee);
          }
        } else if (h === 'class') {
          if (obj.member.is_member) {
            value = '会員';
          } else if (obj.member.is_training_group_member) {
            value = '稽古会会員';
          } else {
            value = '非会員';
          }
        } else if (h === 'paymentMethod') {
          if (stripeCustomers.length > 0) {
            const customer = stripeCustomers.find(
              (c) => c.uuid === obj.member.uuid
            );
            if (customer) {
              if (customer.stripe_customer?.by_stripe) {
                value = 'クレジットカード';
              } else {
                value = '現金';
              }
            } else {
              value = '現金';
            }
          }
        } else if (h === 'is_screening') {
          if (obj.is_screening) {
            value = '常連';
          } else {
            value = '非常連';
          }
        } else {
          console.log('key', h);
          value = String(obj[h]);
        }

        // if (index === 0) console.log(obj);

        value = `${value
          .replace(/[\r\n]/g, ' ')
          .replace(/[,]/g, '、')
          .replace(/["]/g, '”')}`;

        result.push(value);
      });
      return result;
    });

    const rowHead = [];
    headers.forEach((k) => {
      if (!rejectList.includes(k)) {
        rowHead.push(headersObject[k]);
      }
    });

    rows.unshift(rowHead);

    let csvString = '';
    for (let d of rows) {
      csvString += d.join(',');
      csvString += '\r\n';
    }

    const filename = 'seminar_申込状況_index.csv';
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvString], { type: 'text/csv' });

    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
      //その他ブラウザ
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  };

  // contents
  useEffect(() => {
    if (viewData && seminar && stripeCustomers && permissions.length > 0) {
      const headers = [
        {
          field: 'id',
          headerName: 'ID',
          width: 10,
        },
        {
          field: 'uuid',
        },
        {
          field: 'memberUuid',
        },
        {
          field: 'name',
          headerName: '申込者氏名',
          width: 100,
        },
        {
          field: 'classification',
          headerName: '会員種別',
          width: 80,
        },
        {
          field: 'payment_method',
          headerName: '支払方法',
          width: 80,
          cellClassName: (params) =>
            params.value === 'カード' ? 'credit' : 'cash',
        },
        {
          field: 'recieved',
          headerName: '領収区分',
          width: 70,
          cellClassName: (params) =>
            params.value === '領収' || params.value === '無料'
              ? 'recieved'
              : 'non-recieved',
        },
        {
          field: 'part1',
          headerName: '第1部',
          width: 60,
        },
        {
          field: 'part2',
          headerName: '第2部',
          width: 60,
        },
        {
          field: 'part3',
          headerName: '第3部',
          width: 60,
        },
        {
          field: 'part4',
          headerName: '懇親会',
          width: 60,
        },
        {
          field: 'fee',
          headerName: '参加費',
          width: 70,
        },
      ];

      const operationBtn = [
        {
          field: 'detailBtn',
          headerName: '人物詳細',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <>
              {permissions.includes('seminar_staff') ? (
                <div className={classes.distributionBtnConfirm}>
                  <button>詳細</button>
                </div>
              ) : (
                <div className={classes.detailBtn}>
                  <button
                    onClick={() => handleClick('detail', params.row.memberUuid)}
                  >
                    詳細
                  </button>
                </div>
              )}
            </>
          ),
        },
        {
          field: 'cancelBtn',
          headerName: 'キャンセル扱いにする',
          sortable: false,
          width: 180,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <>
              <div className={classes.cancelBtn}>
                <button
                  // disabled={permissions.includes('admin') ? false : DISABLED}
                  onClick={() =>
                    handleClick(
                      'cancel',
                      params.row.uuid,
                      params.row.name,
                      params.row.memberUuid
                    )
                  }
                >
                  実行
                </button>
              </div>
            </>
          ),
        },
      ];
      const columnsDataset = headers.concat(operationBtn);
      setColumns(columnsDataset);
      setRows(
        viewData.map((d, index) => {
          const result = {};
          result['id'] = index + 1;
          result['uuid'] = d.uuid;
          result['memberUuid'] = d.member.uuid;
          result['name'] = d.member.last_name + d.member.first_name;
          result['classification'] = d.member.roushi_is
            ? '老師'
            : d.member.member_of_general_is
            ? various.general_member
            : d.member.member_of_num_is
            ? various.num_member
            : d.member.is_member
            ? various.memberDesignations
            : d.member.is_training_group_member
            ? various.otherMemberDesignations
            : various.nonMemberDesignations;
          result['recieved'] = d.received_is
            ? '領収'
            : d.entrance_fee === 0
            ? '無料'
            : '未領収';
          result['part1'] = d.part1 ? '参加' : '-';
          result['part2'] = d.part2 ? '参加' : '-';
          result['part3'] = d.part3 ? '参加' : '-';
          result['part4'] = d.part4 ? '参加' : '-';

          if (stripeCustomers.length > 0) {
            const customer = stripeCustomers.find(
              (c) => c.email === d.member.email
            );
            if (customer) {
              if (customer.stripe_customer?.by_stripe) {
                result['payment_method'] = 'カード';
              } else {
                result['payment_method'] = '現金';
              }
            }
          } else {
            result['payment_method'] = '現金';
          }

          // console.log('assistance:', d);

          result['fee'] = d.entrance_fee + '円';
          // 上記のとおり「entrance_fee」フィールドを追加してSeminarAssistanceモデルで参加費も管理することにしたので、以下のコードは不要になったが、
          // 経過措置として移行直前に申し込んだ人がいることを想定してしばらく残しておく。
          // if (d.entrance_fee === 0) {
          //   let fee;
          //   if (d.member.is_member | d.member.is_training_group_member) {
          //     fee = seminar.membership_fee;
          //     if (seminar.is_screening) {
          //       if (d.is_screening) {
          //         // 常連組のスクリーニングを申し込みフォームで行っている。1/3というのは、2023年５月開催の「凝縮稽古」のみなのかもしれない。場当たり的な対応。
          //         fee = fee / 3;
          //       }
          //     }
          //   } else {
          //     fee = seminar.entry_fee;
          //   }
          //   const unitPrice = fee / komaNum;
          //   result['fee'] =
          //     [d.part1, d.part2, d.part3].filter(Boolean).length * unitPrice +
          //     '円';
          // }

          return result;
        })
      );
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [viewData, seminar, stripeCustomers, permissions]);

  const weekday = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'];

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth && seminar && eventDate ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">セミナー申込状況一覧</span>
              <span>{date}</span>
            </div>
            <div>
              <span>1部申込数</span>
              <span>{part1Num ? part1Num : 0}件</span>
              <span>2部申込数</span>
              <span>{part2Num ? part2Num : 0}件</span>
              <span>3部申込数</span>
              <span>{part3Num ? part3Num : 0}件</span>
              <span>純申込数</span>
              <span>{seminar.num_of_applicans}件</span>
            </div>
          </div>
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.seminarSpec}>
            <div id="spec1">
              <div>
                <div>タイトル&nbsp;:&nbsp;</div>
                <span>
                  {seminar.title}
                  {seminar.serial_number ? ' #' + seminar.serial_number : null}
                </span>
              </div>
              <div>
                <div>講師&nbsp;:&nbsp;</div>
                <span>{seminar.instructor.name}</span>
              </div>
              <div>
                <div>日時&nbsp;:&nbsp;</div>
                <span>
                  {formatDate(eventDate, 'date')}
                  {weekday[eventDate.getDay()]} {eventDate.getHours()}:
                  {eventDate.getMinutes()} ~
                </span>
              </div>
              <div>
                <div>対象&nbsp;:&nbsp;</div>
                <span>{seminar.subject}</span>
              </div>
              <div>
                <div>場所&nbsp;:&nbsp;</div>
                <span>
                  {seminar.place.facility_name}&nbsp;{seminar.hall.name}
                </span>
              </div>
            </div>
            <div id="spec2">
              <div>
                <div>会員参加費&nbsp;:&nbsp;</div>
                <span>{seminar.membership_fee}円 / 日</span>
              </div>
              <div>
                <div>一般参加費&nbsp;:&nbsp;</div>
                <span>{seminar.entry_fee}円 / 日</span>
              </div>
              <div>
                <div>決済方法&nbsp;:&nbsp;</div>
                <span>
                  {seminarPaymentMethod === 'is_mixed'
                    ? '全支払方法OK'
                    : seminarPaymentMethod === 'is_only_cash'
                    ? '現金決済のみ'
                    : seminarPaymentMethod === 'is_only_credit_card'
                    ? 'クレジットカード決済のみ'
                    : null}
                </span>
              </div>
              <div id="notes">
                <div>注意事項&nbsp;:&nbsp;</div>
                <span>{seminar.notes}</span>
              </div>
            </div>
          </div>
          <div className={classes.forManagement}>
            {seminar.information_to_management ? (
              <>
                <span>※運営への申し送り事項</span>
                {seminar.information_to_management}
              </>
            ) : null}
          </div>
          <hr />
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <button id="btn1" onClick={() => handleMember('all')}>
                すべて
              </button>
              <button id="btn2" onClick={() => handleMember('one')}>
                1部参加
              </button>
              <button id="btn4" onClick={() => handleMember('two')}>
                2部参加
              </button>
              <button id="btn3" onClick={() => handleMember('three')}>
                3部参加
              </button>
              <button id="btn5" onClick={() => handleMember('four')}>
                懇親会参加
              </button>
            </div>
          </div>
          <div className={classes.downLoadBtnContainer}>
            <div id="dlBtn1">
              <span>DownLoad&nbsp;:&nbsp;</span>
            </div>
            <div id="dlBtn2">
              <button id="dlbtn1" onClick={() => downloadCsv()}>
                <img src={imageIndex.CloudDownloadIcon} alt="dlicon" />
              </button>
            </div>
          </div>
          <div className={classes.freeWrightBtnContainer}>
            <div id="freeBtn1">
              <span>自由記入欄一覧&nbsp;:&nbsp;</span>
            </div>
            <div id="freeBtn2">
              <button
                id="freebtn1"
                onClick={() => handleClick('text', seminar.uuid)}
              >
                一覧
              </button>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Box
                className={classes.dataGrid}
                sx={{
                  '& .recieved, .credit': {
                    color: 'green',
                  },
                  '& .non-recieved, .cash': {
                    color: 'red',
                  },
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={PAGESIZE}
                  rowsPerPageOptions={[PAGESIZE]}
                  density="comfortable"
                  columnVisibilityModel={{ uuid: false, memberUuid: false }}
                  pagination={true}
                  autoPageSize={true}
                  rowHeight={ROWHEIGHT}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
              <StandardDialog
                open={dialog}
                handleClose={handleClose}
                text={{
                  title: `${canceler}氏の申し込みを強制キャンセルします。`,
                  content: `本セミナーの申し込みをキャンセルする方は『${canceler}氏』です。お間違いなきようご注意ください。`,
                  agree: 'OK!',
                  disagree: 'STOP!',
                }}
                func={{ agreeFunc: handleCancel, disagreeFunc: null }}
              />
            </>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleAuth}
              >
                ログイン
              </span>
              してください。
            </div>
          )}
        </>
      )}
      <Footer />
    </>
  );
};
export default SeminarApplySituation;
